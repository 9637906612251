import React, { HTMLAttributes } from 'react';
import logoImage from './testlogo.png'; // Import the image

interface IconProps extends HTMLAttributes<HTMLImageElement> {
    width?: number;
    height?: number;
    alt?: string;
}

export const Logo = ({ height = 100, width = 100, alt = 'Logo', ...restProps }: IconProps) => {
    return (
        <img src={logoImage} width={width} height={height} alt={alt} {...restProps} />
    );
};